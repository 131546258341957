import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_EVENTS, TRAINER_EVENTS_CREATE } from 'Consts/routes';
import { EVENT_CATEGORY_DEFAULT } from 'Consts/events';
import { API_RESOURCE_USER } from 'Consts/apiResources';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Planner from 'Components/trainer/events/Planner';

export const TAB_DATA = 'data';

export default class TrainerEventsCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                userId: PropTypes.string,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            singleUser: PropTypes.func,
        }),
    };

    static defaultProps = {};
    
    state = {
        predefinedState: {},
    };

    defaultQuery = {
        userId: '',
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();
        const { userId } = queryObject;

        if (userId) {
            actions.singleUser({ id: userId })
                .then(response => {
                    const result = response.payload[API_RESOURCE_USER];         
                    this.setState(prevState => ({
                        predefinedState: {
                            ...prevState.predefinedState,
                            category: EVENT_CATEGORY_DEFAULT,
                            user: result,
                        },
                    }));
                });
        }
    }

    render() {
        const { predefinedState } = this.state;
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="adept-events-create"
                styles={require('./styles')}
            >
                <PageContent
                    elementMode={true}
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }, {
                        to: withVariables(TRAINER_EVENTS.path),
                        label: 'Wydarzenia',
                    }, {
                        to: withVariables(TRAINER_EVENTS_CREATE.path,),
                        label: 'Zaplanuj wydarzenie',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Zaplanuj wydarzenie"
                        tabs={[{
                            key: TAB_DATA,
                            label: 'Dane',
                            children: (
                                <Planner
                                    location={location}
                                    history={history}
                                    data={{
                                        ...predefinedState,
                                    }}
                                    forcePlanner={true}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}